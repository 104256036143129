<template>
	<div class="app-container">
		<Box :type="'info'" :active="true">
			<div slot="box-body">
				<ul class="rawUl">
					<li>{{$t('products.pending.box1')}}</li>
					<!-- <li v-if="checkLiveMode"><strong>Deoarece sunteti in mediul de testare, produsele se vor aproba automat odata la 1 minut.</strong></li> -->
				</ul>
			</div>
		</Box>
		<el-card class="box-card">
			<div slot="header" class="clearfix">
				<span>{{$t('products.pending.pending')}}</span>
				<span style="float: right">
					<el-button type="warning" size="mini" icon="el-icon-delete" plain @click.native="deleteProducts">
						{{$t('products.pending.delete')}}</el-button>
				</span>
			</div>
			<Box :type="'warning'" :active="true">
				<div slot="box-body">
					<ul class="rawUl">
						<li>{{$t('products.pending.box2')}}</li>
						<!-- <li v-if="checkLiveMode"><strong>Deoarece sunteti in mediul de testare, produsele se vor aproba automat odata la 1 minut.</strong></li> -->
					</ul>
				</div>
			</Box>
			<div class="center">
				<el-pagination layout="prev, pager, next, total" :page-size="limit" :current-page="currentPage"
					:total="count" @current-change="changePage">
				</el-pagination>
			</div>
			<div class="separator"></div>

			<el-table ref="productsTable" :data="products" style="width: 100%" border
				@selection-change="handleSelectionChange" size="small">
				<el-table-column type="selection" width="55">
				</el-table-column>
				<el-table-column :label="$t('general.name')" prop="products_name">
				</el-table-column>
				<el-table-column :label="$t('general.model')" prop="products_model" width="180">
				</el-table-column>
				<el-table-column property="pretf" :label="$t('products.pending.pretf')" width="120">
				</el-table-column>
				<el-table-column property="pretlei" :label="$t('products.pending.price')" show-overflow-tooltip
					width="120">
				</el-table-column>
				<el-table-column label="Status" show-overflow-tooltip width="120">
					<template slot-scope="scope">
						{{formatProductStatus(scope.$index)}}
					</template>
				</el-table-column>
				<el-table-column :label="$t('general.action')" show-overflow-tooltip width="120">
					<template slot-scope="scope">
						<router-link :to="{path: editAddress(scope.row._id)}">
							<el-button size="mini" type="primary" icon="el-icon-edit" plain>{{$t('general.edit')}}
							</el-button>
						</router-link>
					</template>
				</el-table-column>
			</el-table>
			<div class="separator"></div>
			<div class="center">
				<el-pagination layout="prev, pager, next, total" :page-size="limit" :current-page="currentPage"
					:total="count" @current-change="changePage">
				</el-pagination>
			</div>
		</el-card>
	</div>
</template>

<script>
	const Box = () =>
		import('vue-info-box-element');
	import {
		getWaitingProducts,
		deleteWaitingProducts
	} from '@/api/produse';

	import {
		debugPromise
	} from '@/utils/index';
	export default {
		components: {
			Box,
		},
		data() {
			return {
				products: [],
				multipleSelection: [],
				start: 0,
				limit: 25,
				pages: 0,
				ccpage: 0,
				count: 0,
				forceCount: false,
				currentPage: 1,
			};
		},
		methods: {
			formatProductStatus(index) {
				return parseInt(this.products[index].status) === 1 ? this.$t('products.pending.active') : this.$t(
					'products.pending.inactive');
			},
			readProducts() {
				const params = {
					start: this.start,
					limit: this.limit
				};
				getWaitingProducts(params).then((res) => {
					if ('undefined' == typeof res) {
						this.$router.push({
							name: 'Profil public'
						}).catch(() => {});
						return;
					}
					if (typeof res.message === 'undefined') return false;
					this.products = res.message.data;
					this.start = res.message.start;
					this.limit = parseInt(res.message.limit);
					if (typeof res.message.count !== 'undefined') this.count = parseInt(res.message.count);
				}).catch((e) => {
					this.$reportToSentry(e, {
						extra: {
							fn: 'getWaitingProducts',
							params
						}
					});
					debugPromise('getWaitingProducts', e);
				});
			},
			deleteProducts() {
				const products = this.multipleSelection.map((item) => item.products_model);

				if (this.multipleSelection.length < 1 || products.length < 1) {
					this.sbMsg({
						type: 'warn',
						message: this.$t('products.pending.no-product.selected'),
					});
					return false;
				}

				deleteWaitingProducts({
					products: products
				}).then((res) => {
					this.readProducts();
				}).catch((e) => {
					this.$reportToSentry(e, {
						extra: {
							fn: 'deleteWaitingProducts',
							params: {
								products: products
							}
						}
					});
					debugPromise('deleteWaitingProducts', e);
				});
			},
			editAddress(approveId) {
				return '/produse/produs-asteptare/' + approveId;
			},
			handleSelectionChange(val) {
				this.multipleSelection = val;
			},
			changePage(page) {
				this.start = page * this.limit - this.limit;
				this.currentPage = page;
				this.readProducts();
			}
		},
		created() {
			this.readProducts();
		}
	};
</script>